























































import Vue from "vue";
import { mapGetters } from "vuex";
import { TENANT_INFO } from "@/store/modules/candidates/constants";
import { format_assessment_score, format_overall_score } from "@/utils/global";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "AssessmentHeader",
  methods: { format_assessment_score, format_overall_score },
  computed: {
    ...mapGetters("candidate", {
      tenant_info: TENANT_INFO
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  props: {
    score: {
      type: Number
    }
  }
});
